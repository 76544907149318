import React, { Component } from 'react'

export default class Work extends Component {
    render() {
        return (
            <section class="what-we-do-wrapper">
              <div class="container">
                <div class="section-heading">
                  <h2>What <span>We do</span></h2>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="single-item"> <i class="icon icon-tablet"></i>
                      <h3>Web Design</h3>
                      <span class="line"></span>
                      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod voluptatem</p>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="single-item active"> <i class="icon icon-pictures"></i>
                      <h3>Clean Design</h3>
                      <span class="line"></span>
                      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod voluptatem</p>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="single-item"> <i class="icon icon-target"></i>
                      <h3>Performance</h3>
                      <span class="line"></span>
                      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod voluptatem</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        )
    }
}
