import React, { Component } from "react";

export default class Youtube extends Component {
  render() {
    return (
      <section class="our-team-wrapper" data-scroll-index="4">
        <div class="container">
          <div class="section-heading">
            <h2>Youtube</h2>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="single-team">
                <div class="team-img">
                  <iframe
                    style={{ width: "100%" }}
                    src="https://www.youtube.com/embed/rQ5OrcHVd9A"
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="single-team">
                <div class="team-img">
                  <iframe
                    style={{ width: "100%" }}
                    src="https://www.youtube.com/embed/09gdrCNvshc"
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="single-team">
                <div class="team-img">
                  <iframe
                    style={{ width: "100%" }}
                    src="https://www.youtube.com/embed/2axUF8G9U"
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="single-team">
                <div class="team-img">
                  <iframe
                    style={{ width: "100%" }}
                    src="https://www.youtube.com/embed/rQ5OrcHVd9A"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
