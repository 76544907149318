import React, { Component } from 'react'

export default class Testimonial extends Component {
    render() {
        return (
            <section class="testimonials-wrapper" data-scroll-index="5">
  <div class="container">
    <div class="section-heading">
      <h2>Clients <span>Say</span></h2>
    </div>
    <div class="row">
      <div id="testimonials" class="owl-carousel">
        <div class="item">
          <div class="clients-wrapper">
            <div class="clients-img"> <img src="http://via.placeholder.com/290x290/000/fff" alt="" /> </div>
            <div class="clients-text">
              <h5>Aaron Finch</h5>
              <span>Web Developer</span> </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
          </div>
        </div>
        <div class="item">
          <div class="clients-wrapper">
            <div class="clients-img"> <img src="http://via.placeholder.com/290x290/000/fff" alt="" /> </div>
            <div class="clients-text">
              <h5>Aaron Finch</h5>
              <span>Web Developer</span> </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
          </div>
        </div>
        <div class="item">
          <div class="clients-wrapper">
            <div class="clients-img"> <img src="http://via.placeholder.com/290x290/000/fff" alt="" /> </div>
            <div class="clients-text">
              <h5>Aaron Finch</h5>
              <span>Web Developer</span> </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
          </div>
        </div>
        <div class="item">
          <div class="clients-wrapper">
            <div class="clients-img"> <img src="http://via.placeholder.com/290x290/000/fff" alt="" /> </div>
            <div class="clients-text">
              <h5>Aaron Finch</h5>
              <span>Web Developer</span> </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
          </div>
        </div>
        <div class="item">
          <div class="clients-wrapper">
            <div class="clients-img"> <img src="http://via.placeholder.com/290x290/000/fff" alt="" /> </div>
            <div class="clients-text">
              <h5>Aaron Finch</h5>
              <span>Web Developer</span> </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
          </div>
        </div>
        <div class="item">
          <div class="clients-wrapper">
            <div class="clients-img"> <img src="http://via.placeholder.com/290x290/000/fff" alt="" /> </div>
            <div class="clients-text">
              <h5>Aaron Finch</h5>
              <span>Web Developer</span> </div>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
        )
    }
}
