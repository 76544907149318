import React, { Component } from "react";
import Navigation from "../Navigation";
import Welcome from "./Welcome";
import Work from "./Work";
import About from "./About";
import Service from "./Service";
import Youtube from "./Youtube";
import Testimonial from "./Testimonial";
import Contact from "./Contact";
import Footer from "../Footer";

export default class Home extends Component {
  render() {
    return (
      <>
        <Navigation />
        <Welcome />
        <Work />
        <Youtube />
        <Testimonial />
        <Contact />
        <Footer />
      </>
    );
  }
}
