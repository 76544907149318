import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <section class="about-wrapper" data-scroll-index="2">
        <div class="container">
          <div class="section-heading">
            <h2>
              About <span>Us</span>
            </h2>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-7">
              <h3>Experience since 2014</h3>
              <p>
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book
              </p>
              <h3>Experience since 2017</h3>
              <p>
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.
              </p>
              <p>
                It was popularised in the 1960s with the release of Letraset
                sheets containing Lorem Ipsum passages, and more recently with
                desktop.
              </p>
            </div>
            <div class="col-lg-5 col-sm-12 d-md-none d-lg-block">
              <div class="row about-img">
                <div class="col-sm-6 col-xs-6">
                  <img
                    src="http://via.placeholder.com/273x214/000/fff"
                    alt=""
                  />
                  <img
                    src="http://via.placeholder.com/273x214/000/fff"
                    alt=""
                  />{" "}
                </div>
                <div class="col-sm-6 col-xs-6 second">
                  <img
                    src="http://via.placeholder.com/273x451/000/fff"
                    alt=""
                  />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
