import React, { Component } from 'react'

export default class Service extends Component {
    render() {
        return (
            <section class="our-services-wrapper" data-scroll-index="3">
  <div class="container">
    <div class="section-heading">
      <h2>Our <span>Services</span></h2>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="single-service">
          <div class="fig_caption">
            <div class="icon"><i class="icon icon-desktop"></i> </div>
            <div class="details">
              <h3>Laser Show</h3>
              <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce aliquet, massa ac ornare feugiat, nunc dui auctor ipsum..</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="single-service">
          <div class="fig_caption">
            <div class="icon"><i class="icon icon-camera"></i> </div>
            <div class="details">
              <h3>Laser Events</h3>
              <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce aliquet, massa ac ornare feugiat, nunc dui auctor ipsum..</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="single-service">
          <div class="fig_caption">
            <div class="icon"><i class="icon icon-browser"></i> </div>
            <div class="details">
              <h3>Development</h3>
              <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce aliquet, massa ac ornare feugiat, nunc dui auctor ipsum..</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="single-service">
          <div class="fig_caption">
            <div class="icon"><i class="icon icon-mobile"></i> </div>
            <div class="details">
              <h3>Responsive</h3>
              <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce aliquet, massa ac ornare feugiat, nunc dui auctor ipsum..</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="single-service">
          <div class="fig_caption">
            <div class="icon"><i class="icon icon-gears"></i> </div>
            <div class="details">
              <h3>Easy Customization</h3>
              <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce aliquet, massa ac ornare feugiat, nunc dui auctor ipsum..</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="single-service">
          <div class="fig_caption">
            <div class="icon"><i class="icon icon-envelope"></i> </div>
            <div class="details">
              <h3>Support</h3>
              <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce aliquet, massa ac ornare feugiat, nunc dui auctor ipsum..</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
        )
    }
}
