import React, { Component } from "react";
import video from "../../assets/videos/Laser Show- Creaive Laser Systems.mp4";
export default class Welcome extends Component {
  render() {
    return (
      <section class="welcome-area text-center" data-scroll-index="1">
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
          style={{ height: "100%" }}
        >
          <source src={video} type="video/mp4" />
        </video>
        <div class="overlay-bg-70 flex-center">
          <div class="container">
            <div class="welcome-text">
              {/* <h4 class="color-eee fw-300 mb-20px">We Are Creative</h4> */}
              <h1 class="mb-20px color-fff fw-100">
                WE ARE <span class="color-another fw-700">CREATIVE LASER </span>
                LIGHT SHOWS
              </h1>
              <h4 class="color-eee fw-300 mb-20px ">
                Let us add a sparkle to your next event.
              </h4>
              <a
                class="main-btn btn-1 mt-30px mr-5px ml-5px"
                href="#"
                data-scroll-nav="3"
              >
                Services
              </a>{" "}
              <a
                class="main-btn btn-2 mt-10px ml-5px mr-5px"
                data-scroll-nav="7"
                href="#"
              >
                Contact Us
              </a>{" "}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
