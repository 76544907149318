import React, { Component } from 'react'
import logo_normal from '../assets/images/Logo_black Bg.png'
import logo_dark from '../assets/images/logo red txt black.png'
export default class Navigation extends Component {
    render() {
        return (
            <nav class="navbar navbar-expand-md">
              <div class="container"> <a class="navbar-brand" href="#home"> <img src={logo_normal} class="normal-logo" alt=""/> <img src={logo_dark} class="change-logo" alt=""/> </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar" aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation"> <span class="fa fa-bars"></span> </button>
                <div class="collapse navbar-collapse" id="main-navbar">
                  <ul class="navbar-nav ml-auto">
                    <li> <a class="nav-link" href="" data-scroll-nav="1">Home</a> </li>
                    {/* <li> <a class="nav-link" href="#" data-scroll-nav="2">About</a> </li> */}
                    {/* <li> <a class="nav-link" href="#" data-scroll-nav="3">Services</a> </li> */}
                    <li> <a class="nav-link" href="#" data-scroll-nav="4">Youtube</a> </li>
                    <li> <a class="nav-link" href="#" data-scroll-nav="5">Testimonials</a> </li>
                    {/* <li> <a class="nav-link" href="#" data-scroll-nav="6">Portfolio</a> </li> */}
                    <li> <a class="nav-link" href="#" data-scroll-nav="7">Contact Us</a> </li>		
                  </ul>
                </div>
              </div>
            </nav>
        )
    }
}
