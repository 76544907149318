import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer class="footer-wrapper">
            <div class="container">
              <div class="footer-icon-area">
                <div class="row">
                  <div class="col-md-4 col-sm-6">
                    <div class="footer-icon-wrapper">
                      <div class="footers-icon"> <a href="#"> <i class="fa fa-phone"></i> </a> </div>
                      <div class="footer-icon-text">
                        <h4>call us</h4>
                        <span>+91 9035222777</span> </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6">
                    <div class="footer-icon-wrapper">
                      <div class="footers-icon"> <a href="#"> <i class="fa fa-envelope"></i> </a> </div>
                      <div class="footer-icon-text">
                        <h4>Email Us</h4>
                        <span>contact@creativelasersystems.com</span> </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6">
                    <div class="footer-icon-wrapper">
                      <div class="footers-icon"> <a href="#"> <i class="fa fa-home"></i> </a> </div>
                      <div class="footer-icon-text">
                        <h4>Location</h4>
                        <span>#38 1st Cross Bhuvaneshwari Nagar,
T. Dasarahalli, Bangalore - 560057</span> </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="copyright">
                <p>&copy; Copyright <span id="year">2020</span>, Powered by | IntensiveLabs Pvt Ltd.</p>
              </div>
            </div>
          </footer>
        )
    }
}
