import React, { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <section class="contact-wrapper" data-scroll-index="7">
        <div class="container">
          <div class="section-heading">
            <h2>
              Contact <span>Us</span>
            </h2>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="contact-info text-center">
                <i class="icon icon-map-pin"></i>
                <h5>Location</h5>
                <span>
                  #38 1st Cross Bhuvaneshwari Nagar, <br />T. Dasarahalli, Bangalore -
                  560057
                </span>{" "}
              </div>
            </div>
            <div class="col-sm-4">
              <div class="contact-info text-center">
                {" "}
                <i class="icon icon-phone"></i>
                <h5>Support</h5>
                <span>
                  +91 9035222777 <br />
                  contact@creativelasersystems.com
                </span>{" "}
              </div>
            </div>
            <div class="col-sm-4">
              <div class="contact-info text-center">
                {" "}
                <i class="icon icon-toolbox"></i>
                <h5>Follow Us</h5>
                <div class="contact-social">
                  {" "}
                  <a href="#">
                    {" "}
                    <i class="icon icon-facebook"></i>{" "}
                  </a>{" "}
                  <a href="#">
                    {" "}
                    <i class="icon icon-twitter"></i>{" "}
                  </a>{" "}
                  <a href="#">
                    {" "}
                    <i class="icon icon-googleplus"></i>{" "}
                  </a>{" "}
                  <a href="#">
                    {" "}
                    <i class="icon icon-linkedin"></i>{" "}
                  </a>{" "}
                  <a href="#">
                    {" "}
                    <i class="icon icon-dribbble"></i>{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
          <div class="contact-bg">
            <div class="section-title ">
              <h2>Have Any Questions ?</h2>
            </div>
            <form class="contact-form" action="#">
              <div class="row">
                <div class="form-group col-sm-6">
                  <input
                    class="form-control"
                    placeholder="Your Name*"
                    required
                    type="text"
                  />
                </div>
                <div class="form-group col-sm-6 mb-20">
                  <input
                    class="form-control"
                    placeholder="Your Email :"
                    type="text"
                  />
                </div>
                <div class="form-group col-sm-12 mb-20">
                  <textarea
                    class="form-control"
                    name="message"
                    cols="30"
                    rows="10"
                    placeholder="Your Question :"
                  ></textarea>
                  <div class="text-center mt-20">
                    <button>Send Question</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="google-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198710.35112897935!2d-98.51489117772236!3d38.904562823631146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited+States!5e0!3m2!1sen!2sin!4v1471865832140"
            allowfullscreen=""
          ></iframe>
        </div>
      </section>
    );
  }
}
